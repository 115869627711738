<template>
  <div class="form-steps-wrapper h-100">
    <div class="step-icon-wrapper h-100">
      <div>
        <div class="icon-container">
          <div class="transfer-vertical-divider"></div>
          <div>
            <div class="icon-wrapper"><i class="icon-transfer"></i></div>
          </div>
          <div class="transfer-vertical-divider"></div>
        </div>
        <span>
          {{ $t("transaction.new_transfer") }}
          <br />
          {{ $t("transaction.of_money") }}
        </span>
      </div>
    </div>
    <div class="step-form-wrapper h-100">
      <header-with-divider
        :text="$t('transaction.new_money_transfer')"
        color="blue"
      />
      <div class="form-container h-100" v-if="isTransferAvailable">
        <form @submit.prevent="handleSearchReceiverForm">
          <be-form-error v-if="error" :message="error" class="mt-4" />
          <div class="h-100">
            <template v-if="isTransferInit">
              <div class="form-input-wrapper">
                <div class="form-input">
                  <BaseInput
                    v-model="email"
                    :class="{
                      'is-invalid': $v.email.$error,
                    }"
                    :errorMessage="emailErrorMsg"
                    :label="$t('transaction.receiver_email')"
                    :placeholder="$t('transaction.receiver_email_placeholder')"
                    type="email"
                    @blur="$v.email.$touch()"
                  ></BaseInput>
                </div>
              </div>
              <div class="divider"></div>
              <div class="form-input-wrapper">
                <div class="form-input">
                  <BaseInput
                    v-model.number="amount"
                    :class="{
                      'is-invalid': $v.amount.$error,
                    }"
                    :errorMessage="amountErrMsg"
                    :label="$t('transaction.sending_amount')"
                    :placeholder="$t('transaction.sending_amount_placeholder')"
                    type="text"
                    @blur="$v.amount.$touch()"
                  ></BaseInput>
                  <small v-if="!amountErrMsg" class="form-text text-muted">
                    {{ $t("transaction.amount_without_tax") }}:
                    <span class="balance f-w-500">{{
                      `${balance} ${currency}`
                    }}</span>
                  </small>
                </div>
              </div>
            </template>
            <template v-if="!isTransferInit">
              <div class="transfer-success h-100">
                <i class="success-icon icon-shield-check"></i>
                <label class="success-text">
                  {{ $t("transaction.transfer_of") }}
                  <b class="amount">{{ `${amount} ${currency}` }}</b>
                  {{ $t("transaction.to_account") }}<br />
                  {{ email }}
                </label>
                <span>
                  {{ $t("transaction.amount_of") }}
                  {{ `${amountToPaid} ${currency}` }}
                  {{ $t("transaction.withdraw_in_account") }}
                </span>
                <br />
                <span>
                  {{ $t("transaction.transfer_fee") }} {{ feePercent }}% =
                  <b class="amount">{{ `${fees} ${currency}` }}</b>
                </span>
                <small class="beneficiary">
                  {{ $t("transaction.receiver") }}:
                  {{ receiverName || $t("transaction.no_specified") }}
                </small>
              </div>
            </template>
          </div>
          <div class="button-container">
            <div class="divider"></div>
            <div class="buttons">
              <a href="#" @click.prevent="cancelForm" class="btn">
                {{ $t("common.cancel") }}
              </a>
              <BaseButton
                :buttonClass="
                  `be-btn col primary ${!isTransferInit ? 'green' : 'blue'}`
                "
                :disabled="!isValidForm"
                :loading="loading"
                :show-loading="true"
              >
                {{
                  isTransferInit
                    ? $t("transaction.account_find")
                    : $t("common.confirm")
                }}
              </BaseButton>
            </div>
          </div>
        </form>
      </div>
      <no-content
        v-else
        :text="$t('base.messages.disableTransfer')"
        icon="icon-transfer"
      />
    </div>
    <b-modal
      id="transfer-details-modal"
      ref="detailsModal"
      no-fade
      size="lg"
      top
    >
      <template slot="modal-header">
        <span />
        <span class="modal-title"> {{ $t("common.attention") }}! </span>
        <span @click="hideModal">
          <i class="icon-close f-24" />
        </span>
      </template>
      <template slot="modal-footer">
        <span class="f-w-600 fermer mr-4" @click="hideModal">
          {{ $t("common.close") }}
        </span>
        <button class="ml-4 be-btn primary lg blue" @click="confirmTransfer">
          {{ $t("common.confirm_operation") }} ?
        </button>
      </template>
    </b-modal>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { maxValue, minValue, required, email } from "vuelidate/lib/validators";
import HeaderWithDivider from "@/components/common/HeaderWithDivider";
import BeFormError from "@/components/common/BeFormError";
import NoContent from "@/components/common/NoContent.vue";

export default {
  name: "TransferForm",
  components: { NoContent, HeaderWithDivider, BeFormError },
  data() {
    return {
      loading: false,
      error: null,
      amount: null,
      email: null,
      receiver: null,
    };
  },

  computed: {
    ...mapGetters("auth", [
      "userStats",
      "userBalance",
      "isCurrentUserEmail",
      "isCurrentUserCountry",
      "currency",
    ]),
    ...mapGetters("transfer", ["isTransferAvailable", "userConfig"]),
    isValidForm() {
      return this.balance >= 0 && !this.$v.$invalid && !this.loading;
    },
    emailErrorMsg() {
      if (this.$v.email.$error) {
        if (!this.$v.email.required) {
          return this.$t("transaction.email_required");
        }
        if (!this.$v.email.email) {
          return this.$t("transaction.email_validated");
        }
        if (!this.$v.email.used) {
          return this.$t("transaction.email_self_validated");
        }
      }
      return "";
    },
    amountErrMsg() {
      if (this.$v.amount.$error) {
        if (!this.$v.amount.required) {
          return this.$t("transaction.amount_required");
        }
        if (!this.$v.amount.minValue) {
          return (
            this.$t("transaction.amount_must_be_greater_than") +
            ` ${this.userConfig.transfer_min_amount} ${this.currency}`
          );
        }
        if (!this.$v.amount.maxValue) {
          return (
            this.$t("transaction.amount_must_be_low_than") +
            ` ${this.maxAmountValue} ${this.currency}`
          );
        }
      }
      return "";
    },
    feePercent() {
      if (this.receiver)
        return this.isCurrentUserCountry(this.receiver.country_id)
          ? parseFloat(this.userConfig.transfer_fee)
          : parseFloat(this.userConfig.transfer_country_fee);
      else return parseFloat(this.userConfig.transfer_fee);
    },
    fees() {
      if (this.feePercent === 0) return 0;
      else
        return ((parseFloat(this.amount) * this.feePercent) / 100).toFixed(0);
    },
    amountToPaid() {
      return this.amount
        ? parseFloat(this.amount) + parseFloat(this.fees)
        : null;
    },
    balance() {
      if (this.amountToPaid)
        return parseFloat(this.userBalance) - parseFloat(this.amountToPaid);
      else return this.userBalance;
    },
    maxAmountValue() {
      return this.userBalance > this.userConfig.transfer_max_amount
        ? this.userConfig.transfer_max_amount
        : this.userBalance;
    },
    isTransferInit() {
      return this.receiver === null;
    },
    receiverName() {
      if (this.receiver)
        return `${this.receiver.first_name ?? ""} ${this.receiver.last_name ??
          ""}`.trim();
      return null;
    },
  },

  methods: {
    openModal(row) {
      this.modalData = row;
      this.$refs.detailsModal.show();
    },
    hideModal() {
      this.$refs.detailsModal.hide();
    },
    confirmTransfer() {
      if (this.isValidForm) {
        this.loading = true;
        this.hideModal();
        this.$store
          .dispatch("transfer/confirmTransfer", {
            amount: this.amount,
            receiver_id: this.receiver.id,
          })
          .then(() => {
            this.$store.dispatch("notification/add", {
              type: "success",
              message: this.$t("transaction.transfer_initiated"),
              is_toast: false,
            });
            this.$router.push({ name: "transfer" });
            this.loading = false;
          })
          .catch(error => {
            if (error.message) this.error = error.message;
            if (error.invalid)
              this.error = this.$t("transaction.transfer_invalid_profile");

            this.receiver = null;
            this.loading = false;
          });
      }
    },
    handleSearchReceiverForm() {
      if (this.isValidForm) {
        this.error = null;
        if (this.receiver) {
          this.openModal();
        } else {
          this.loading = true;
          this.$store
            .dispatch("transfer/searchReceiverByEmail", this.email)
            .then(receiver => {
              this.receiver = receiver;
              this.loading = false;
              if (this.balance < 0) {
                this.error = this.$t("transaction.invalid_balance");
              }
            })
            .catch(error => {
              if (error.message) this.error = error.message;
              this.loading = false;
            });
        }
      }
    },

    cancelForm() {
      this.error = null;
      if (this.receiver) {
        this.receiver = null;
        this.amount = null;
      } else this.$router.push({ name: "transfer" });
    },
  },

  validations() {
    return {
      amount: {
        required,
        minValue: minValue(this.userConfig.transfer_min_amount),
        maxValue: maxValue(this.maxAmountValue),
      },
      email: {
        required,
        email,
        used: value => !this.isCurrentUserEmail(value),
      },
    };
  },
};
</script>

<style>
/*@import '~@sweetalert2/theme-bootstrap-4/bootstrap-4.css';*/
.modal-title {
  color: #0b1527;
  font-weight: 600;
}

.icon-close {
  cursor: pointer;
}

/*.modal-body {*/
/*  display: none;*/
/*}*/

.fermer {
  cursor: pointer;
}

/deep/ .modal-backdrop {
  background: rgba(255, 255, 255, 0.2) !important;
  backdrop-filter: blur(3px) !important;
}
</style>
